import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';

import { Utils } from '@mvrb-frontend/shared-ui';

const useStyles = makeStyles(theme => ({
  fileIcon: {
    color: '#E67337',
  },
  fileText: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    padding: 0,
    margin: '5px',
  },
  fileSize: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    padding: 0,
    whiteSpace: 'nowrap',
    margin: '5px',
  },
  collapseButton: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: '2px',
    margin: '5px',
    alignSelf: 'baseline',
    top: '-4px',
  },
  caption: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const AttachmentsList = props => {
  const classes = useStyles();
  const { commentSets, attachmentsSize, refs, labels, toggleExpanded } = props;
  const [isAttachmentSectionOpen, setAttachmentsOpen] = useState(true);

  const displayHeader = commentSet => {
    return commentSet.comments.some(
      comment => comment.attachments.length > 0 || comment.response.length > 0,
    );
  };

  const scrollToFile = (commentSetId, fileId) => {
    toggleExpanded(commentSetId, true);
    refs[fileId]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const attachmentLinks = (
    files: { id: string; fileSize: string; filename: string }[],
    commentSet,
  ) => {
    return files.map(file => {
      return (
        <Box my={1} key={file.id}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<DescriptionIcon className={classes.fileIcon} />}
            endIcon={<LinkIcon />}
            onClick={() => scrollToFile(commentSet.id, file.id)}
          >
            <Typography className={classes.fileText} variant="body1">
              {`${file.filename}`}
            </Typography>
            <Typography className={classes.fileSize} variant="body1">
              {`(${Utils.getFileSize(file.fileSize)})`}
            </Typography>
          </Button>
        </Box>
      );
    });
  };

  return (
    <Box>
      <Box display="flex">
        <Typography variant="caption">
          {labels.attachmentsListPrefix} {attachmentsSize}{' '}
          {attachmentsSize > 1 ? 'Attachments' : 'Attachment'}
        </Typography>
        <Box style={{ flexGrow: 12, textAlign: 'right' }}>
          <IconButton
            className={classes.collapseButton}
            onClick={() => setAttachmentsOpen(!isAttachmentSectionOpen)}
          >
            {isAttachmentSectionOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={isAttachmentSectionOpen}>
        {commentSets.map(commentSet => {
          return (
            <React.Fragment key={commentSet.id}>
              {displayHeader(commentSet) && (
                <Box>
                  <Box mt={3} className={classes.caption}>
                    {`${commentSet.organization.name}: ${commentSet.author.displayName}`}
                  </Box>
                  <Box my={1}>
                    {commentSet.comments.map(comment => {
                      return (
                        <Box key={comment.commentId}>
                          {attachmentLinks(comment.attachments, commentSet)}
                          {attachmentLinks(comment.response, commentSet)}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Collapse>
    </Box>
  );
};

AttachmentsList.propTypes = {
  commentSets: PropTypes.array.isRequired,
  attachmentsSize: PropTypes.any.isRequired,
  refs: PropTypes.any,
  labels: PropTypes.any.isRequired,
  expanded: PropTypes.array.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

export default AttachmentsList;
